import React, { Component } from "react";
import Layout from "../components/Layout";
import { Context } from "../state";
import { graphql } from "gatsby";
import AboutLayout from "../components/AboutLayout";
import { HTMLContent } from "../components/Content";
import SEO from "../components/SEO";

class AboutPage extends Component {
  static contextType = Context;

  componentDidMount() {
    const [, dispatch] = this.context;
    dispatch({ type: "PAGE_TYPE", payload: "index" });
  }

  render() {
    const { frontmatter, htmlAst } = this.props.data.markdownRemark;
    return (
      <Layout>
        <SEO
          title={frontmatter.title}
          description={frontmatter.description.substring(0, 150)}
          image={frontmatter.image.childImageSharp.fluid.src}
        />
        <AboutLayout
          title={frontmatter.title}
          description={frontmatter.description}
          image={frontmatter.image}
          content={htmlAst}
          contentComponent={HTMLContent}
        />
      </Layout>
    );
  }
}

export default AboutPage;

export const pageQuery = graphql`
  query aboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      htmlAst
      frontmatter {
        title
        description
        image {
          childImageSharp {
            fluid(maxWidth: 159, quality: 100) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`;
